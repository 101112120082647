@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* spacing vars converted to Mui's spacing config */
:root {
  --1: 0.5rem;
  --2: 1rem;
  --3: 1.5rem;
  --4: 2rem;
  --5: 2.5rem;
  --6: 3rem;
  --7: 3.5rem;
  --8: 4rem;
  --9: 4.5rem;
  --10: 5rem;
  --grey: #9e9e9e;
  --black: #000000;
  --white: #ffffff;
  --blue: #61bee8;
  --yellow: #f2ca04;
  --red: #f44336;
  --orange: #ff9800;
  --green: #4caf50;
  --lightest-grey: #fafafa;
  --light-grey: #eeeeee;
  --light-blue: #85d8ff;
  --light-yellow: #ffe561;
  --light-red: #e57373;
  --light-orange: #ffb74d;
  --light-green: #81c784;
  --dark-grey: #757575;
  --dark-blue: #293860;
  --dark-yellow: #e0c53f;
  --dark-red: #d32f2f;
  --dark-orange: #f57c00;
  --dark-green: #388e3c;
  --darker-grey: #424242;
}

body {
  margin: 0;
  font-family: Montserrat, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
}

main.main {
  padding-top: var(--6);
  padding-bottom: var(--6);
  min-height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  justify-items: center;
  background-color: var(--lightest-grey);
}

hr {
  width: 100%;
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.sr-only-focusable:focus,
.sr-only-focusable:active {
  clip: auto !important;
  -webkit-clip-path: none !important;
  clip-path: none !important;
  height: auto !important;
  margin: auto !important;
  overflow: visible !important;
  width: auto !important;
  white-space: normal !important;
}
